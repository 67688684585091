@import url('../../css/_base.pcss');

@layer base {
    :root {
        --color-primary-lighter: 14, 85%, 64%;
        --color-primary-light: 14, 85%, 60%;
        --color-primary: 14, 85%, 54%;
        --color-primary-dark: 14, 85%, 48%;
        --color-primary-darker: 14, 85%, 40%;

        --color-gradient-start: 1, 68%, 53%;
        --color-gradient-end: 22, 74%, 55%;
    }
}
